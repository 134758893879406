import './App.css';
import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { useSpring, animated } from 'react-spring';

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1];
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

function App() {
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));

  return (
    <div className="App">
      <header className="App-header">
        <div className="player-wrapper">
          <ReactPlayer url='https://www.youtube.com/watch?v=7_UhUQcsYr4' playing={true} width="100%" height="100%"/>
        </div>
        <div className="games-container">
        <a className="game" target="_blank" rel="noreferrer" href={'https://discord.gg/nuVsqtmqJZ'}>
            <animated.div
              class="dcard"
              onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
              onMouseLeave={() => set({ xys: [0, 0, 1] })}
              style={{ transform: props.xys.interpolate(trans) }}
            >
              <label className="game-label">Discord</label>
            </animated.div>
          </a>
          <a className="game" target="_blank" rel="noreferrer" href={'https://api.wypers.net/launchMinecraft'}>
            <animated.div
              class="mcard"
              onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
              onMouseLeave={() => set({ xys: [0, 0, 1] })}
              style={{ transform: props.xys.interpolate(trans) }}
            >
              <label className="game-label">Minecraft</label>
            </animated.div>
          </a>
          <a className="game" target="_blank" rel="noreferrer" href={'https://api.wypers.net/launchValheim'}>
            <animated.div
              class="vcard"
              onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
              onMouseLeave={() => set({ xys: [0, 0, 1] })}
              style={{ transform: props.xys.interpolate(trans) }}
            >
              <label className="game-label">Valheim</label>
            </animated.div>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;